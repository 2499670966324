import React, { useState } from "react"
import styled, { ThemeProvider } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Helmet } from "react-helmet"
import { customMedia } from "../styles/customMedia"
import HeroContentBackground from "../../static/background-hero-content.png"
import { defaultTheme } from "../styles/theme"
import GlobalStyles from "../styles/global"
import Button from "../components/commons/Button"
import Typography from "../components/commons/Typography"
import {
  CEOProfile,
  CEOTestmony,
  HeroContent,
  InvestmentStatistics,
  InvestmentInfos,
  Footer,
} from "../components"

const InvestmentCalculator = React.lazy(() =>
  import("../components/InvestmentCalculator")
)
const LeadForm = React.lazy(() => import("../components/LeadForm"))

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;

  ${customMedia.greaterThan("mediumDesktop")`
    border-radius: 0;
  `}
`

export const BackgroundContainer = styled.div`
  background: url(${HeroContentBackground}) no-repeat;
  background-size: cover;
  background-position: bottom;
`

// Z-INDEX: 3, maior que a legenda da foto do CEO, menor do que os elements que escondem ele
export const FixedButtonFooter = styled.div`
  width: 100%;
  height: 48px;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  z-index: 3;
`
export const HiddenFixedButtonHandler = styled.span`
  z-index: 4;
`

function Home() {
  const {
    typography: { dark },
    colors: { lightOrange },
  } = defaultTheme
  const isSSR = typeof window === "undefined"

  const [investmentValues, setInvestmentValues] = useState(null)
  const [investmentsResultsData, setInvestmentsResultsData] = useState(null)

  return (
    <ThemeProvider theme={defaultTheme}>
      <Helmet title="Simulador de investimento - EQI" defer={false}/>
      <GlobalStyles />
      <MainContainer>
        <BackgroundContainer>
          <HeroContent />
        </BackgroundContainer>
        <CEOProfile />
        {!isSSR && (
          <React.Suspense fallback={<div />}>
            <InvestmentCalculator
              setInvestmentValues={setInvestmentValues}
              setInvestmentsResultsData={setInvestmentsResultsData}
            />
            <LeadForm
              investmentValues={investmentValues}
              investmentsResultsData={investmentsResultsData}
            />
          </React.Suspense>
        )}
        <FixedButtonFooter>
          <Button bgcolor={lightOrange} onClick={() => scrollTo("#calculator")}>
            <Typography type="p" color={dark} size="14px" weight="bold">
              QUERO SIMULAR GRÁTIS
            </Typography>
          </Button>
        </FixedButtonFooter>
        <HiddenFixedButtonHandler>
          <InvestmentStatistics />
          <InvestmentInfos />
          <CEOTestmony />
          <Footer />
        </HiddenFixedButtonHandler>
      </MainContainer>
    </ThemeProvider>
  )
}

export default Home
